<template>
  <div class="p-20 rounded bg-white">
    <div class="relative">
      <div class="text-right">
        <a-space>
          <status-box :status="detail.status">
            <span v-if="detail.status === 10">待审核</span>
            <span v-else-if="detail.status === 20">管理员审核中</span>
            <span v-else-if="detail.status === 30">审核成功</span>
            <span v-else-if="detail.status === 99">审核失败</span>
          </status-box>
        </a-space>
      </div>
      <div>
        <ApplyDisplay :dataSource="detail" />
        <div class="text-center" v-if="detail.status > 0">
          <a-space :size="20" v-if="detail.status === 10">
            <a-button @click="open(false)" type="primary" ghost
              >审核拒绝</a-button
            >
            <a-button @click="open(true)" type="primary">审核通过</a-button>
          </a-space>
        </div>
      </div>
    </div>
    <RefuseModal v-model="refuseVisible" @confirm="expertConfirm" />
  </div>
</template>

<script>
import ApplyDisplay from "./components/applyDisplay.vue";
import {
  getExpertRequirement,
  expertConfirm,
} from "@/api/api/requirement";
export default {
  components: { ApplyDisplay },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    this.getExpertRequirement(id);
  },
  data() {
    return {
      id: "",
      detail: {},
      refuseVisible: false,
      status: true,
    };
  },
  methods: {
    // 打开需求列表
    open(status) {
      this.status = status;
      this.refuseVisible = true;
    },
    // 获取需求接口
    getExpertRequirement(id) {
      getExpertRequirement(id)
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {
          this.$message.error("获取详情失败");
        });
    },
    // 专家审核
    expertConfirm(data) {
      const temp = {
        feedback: data.feedback,
        ok: this.status,
      };
      expertConfirm(this.id, temp)
        .then(() => {
          this.refuseVisible = false;
          this.$message.success("审核成功");
          this.$router.back();
        })
        .catch(() => {
          this.$message.error("审核失败");
        });
    },
  },
};
</script>